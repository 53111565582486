<template>
	<div class="demo-creator contained-popup-content">
		<div>
			<div style="margin-top: 15px;">
				<p class="boldme" style="margin-bottom: 10px;">New Demographic Name</p><input type="text" v-model="newDemoName" style="width: 400px; padding-top: 12px; padding-bottom: 12px; " class="wl-input-text" />
				<button type="button" class="option-button colbtn" @click="saveDemo" style="margin-left: 10px;">Save</button>
			</div>
			<div v-if="store.user.superUser === 1" style="margin-top: 15px; margin-bottom: 25px;">
				<span class='boldme'>Global or User Owned Demo</span><br/><br/>
				<select v-model="saveAsGlobalDemo">
					<option :value="false">User Owner</option>
					<option :value="true">Global Demo</option>
				</select>
			</div>
			<div style="margin-top: 15px;">
				<p class="boldme" style="margin-bottom: 10px; width: 500px;">
					<span>SQL String</span>
					<span style="cursor:pointer; padding: 3px; margin-left: 10px" title="Clear selections" @click="clearSelections"><i class="mdi mdi-close"></i></span>
				</p>
				<textarea v-model="sqlString" class="wl-input-textarea" style="width: 500px; height: 100px;"></textarea>
			</div>

			<div v-if="hasActualYearAgeQ === true" style="margin-top: 15px;">
				<span class='boldme'>Aged between <input type='number' v-model="ageMin" min='10' @change="generateSQLString" /> and <input type='number' v-model="ageMax" max='99' @change="generateSQLString" /></span><br/>
				<span style='font-size: 11px;'>Maximum age 99.  Eg for 10+ set aged between '10 and 99'</span>
			</div>
			<div v-for="q in filteredQuestions" :key="q.qid" class="qchooser" :data-qid="q.qid">
				<p class="boldme" style="margin-bottom: 5px;">
					QID: {{q.qid}}<span v-if="q.colcode && q.colcode.length > 0">, ColCode: {{q.colcode}}</span><span v-if="q.trendkey && q.trendkey.length > 0">, TrendKey: {{q.trendkey}}</span>
				</p>
				<p class="" style="margin-bottom: 10px;">{{q.qtext_stripped}}</p>
				<div v-for="opt in q.options" :key="opt.optid">
					<select class="chooser" :data-optid="opt.optid" @change="generateSQLString" style="margin-bottom: 5px;">
						<option value=""></option>
						<option value="include" class="includer">Include</option>
						<option value="exclude" class="excluder">Exclude</option>
					</select>
					<span style="margin-left: 10px;">{{opt.opttext}}</span>
				</div>
				<br/><br/>
			</div>

		</div>
	</div>
</template>

<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";

export default {
	name: 'DemoCreator',
	mixins: [globalMixin],
	props: {
		survob: {
			type: Object,
			default: () => {}
		},
	},
	components: {},
	data() {
		return {
			store: store,

			newDemoName: '',
			sqlString: '',
			showSQLString: true,
			saveAsGlobalDemo: false,

			ageMin: null,
			ageMax: null,

			isSaving: false,
		}
	},
	computed: {
		hasActualYearAgeQ() {
			if(this.survob.ageq && this.survob.ageq > 0) {
				let q = this.survob.questions.find(item => item.qid === this.survob.ageq)
				if(q) {
					if(['Text','Number','Select-One'].includes(q.type)) {
						return true
					}
				}
			}
			return false
		},
		filteredQuestions() {
			let qs = this.survob.questions.filter(item => !['HeaderText','Number','Text'].includes(item.type))
			if(this.hasActualYearAgeQ && this.survob.ageq > 0) {
				qs = qs.filter(item => item.qid !== this.survob.ageq)
			}
			return qs
		},
	},
	methods: {
		saveDemo() {
			if(this.isSaving === false) {
				if(this.newDemoName.length === 0) {
					alert("Please name your demographic before saving")
					return
				}
				if(this.sqlString.length === 0) {
					alert("Please select at least one option before saving")
					return
				}
				this.isSaving = true
				this.store.showLoader = true
				let isGlobal = 0 //by default demos are owned by the current user
				if(this.store.user.superUser === 1 && this.saveAsGlobalDemo === true) isGlobal = 1

				//clean the SQL string for post and anti-injection
				let dstring = this.sqlString
				dstring = dstring.replace(/=/g,"eq")
				dstring = dstring.replace(/>/g,"gt")
				dstring = dstring.replace(/</g,"lt")
				dstring = dstring.replace(/OR/g,"UU")
				dstring = dstring.replace(/AND/g,"AA")
				dstring = dstring.replace(/'/g,"apost")
				dstring = dstring.replace(/!/g,"exclam")
				let sendob = {
					survid: this.survob.survid,
					demoName: this.newDemoName,
					sqlString: dstring,
					isGlobal: isGlobal,
					demoId: 0, //atm we just allow creating new demos
				}
				let targ = this.store.urlroot + "central/post-vue/dashboard/save-demographic"
				this.simplePost(targ, sendob).then(function (ret) { //use ret in function as returned data
					this.store.showLoader = false
					this.isSaving = false
					if(ret.error) this.showKalertError(ret.error)
					if(ret.success) {
						this.$emit('closesave', ret.success.demoId)
					}
				}.bind(this))
			}
		},
		clearSelections() {
			this.ageMin = null
			this.ageMax = null
			let items = document.getElementsByClassName('chooser')
			for(let it of items) {
				it.value = ''
			}
			this.generateSQLString()
		},
		generateSQLString() {
			let qchoosers = document.getElementsByClassName('qchooser')
			let qsqls = []
			let checkboxes = ['Checkbox','MatrixHeadCheck','MatrixSubCheck','MatrixEndCheck']
			//handle age inputs if they exist
			if(parseInt(this.ageMin) > 0 && parseInt(this.ageMax) > 0) {
				qsqls.push("age>='"+this.ageMin+"' AND age<='"+this.ageMax+"'")
			}
			else if(parseInt(this.ageMin) > 0) {
				qsqls.push("age>='"+this.ageMin+"'")
			}
			else if(parseInt(this.ageMax) > 0) {
				qsqls.push("age<='"+this.ageMax+"'")
			}
			//loop through standard questions
			for(let q of qchoosers) { //each of these is a question
				let qid = parseInt(q.getAttribute('data-qid'))
				let incstrings = []
				let incstring = "";
				let exstrings = []
				let excstring = "";
				let qob = this.survob.questions.find(item => item.qid === qid)
				let incs = []
				let excls = []
				let sels = q.getElementsByClassName('chooser')
				let qfld = "AnsQ"+qid //default QID field
				//handle assigned questions overwrite
				if(this.survob.ageq === qid) qfld = 'age'
				else if(this.survob.genderq === qid) qfld = 'gender'
				//handle trendkeys instead of QID & assigned key if it exists
				if(qob.trendkey && qob.trendkey.length > 0) qfld = qob.trendkey
				for(let s of sels) {
					s.classList.remove('greenback', 'redback')
					if(s.value === 'include') {
						incs.push(parseInt(s.getAttribute('data-optid')))
						s.classList.add('greenback')
					}
					if(s.value === 'exclude') {
						excls.push(parseInt(s.getAttribute('data-optid')))
						s.classList.add('redback')
					}
				}
				if(incs.length > 0) {
					for(let inc of incs) {
						if (checkboxes.includes(qob.type)) {
							incstrings.push(qfld + "='" + inc + "' OR " + qfld + "='" + inc + "' OR " + qfld + " LIKE '%|" + inc + "' OR " + qfld + " LIKE '" + inc + "|%' OR " + qfld + " LIKE '%|" + inc + "|%'");
						}
						else {
							incstrings.push(qfld + "='" + inc + "'")
						}
					}
				}
				incstring = incstrings.join(' OR ')
				if(excls.length > 0) {
					for(let ex of excls) {
						if (checkboxes.includes(qob.type)) {
							exstrings.push(qfld+"!='"+ex+"' AND "+qfld+" NOT LIKE '%|"+ex+"' AND "+qfld+" NOT LIKE '"+ex+"|%' AND "+qfld+" NOT LIKE '%|"+ex+"|%'");
						}
						else {
							exstrings.push(qfld+"!='"+ex+"'")
						}
					}
				}
				excstring = exstrings.join(' AND ')
				if(incstring.length > 0 && excstring.length > 0) {
					qsqls.push("("+incstring+") AND "+excstring)
				}
				else if(incstring.length > 0) {
					qsqls.push(incstring)
				}
				else if(excstring.length > 0) {
					qsqls.push(excstring)
				}
			}
			if(qsqls.length === 0) this.sqlString = ''
			else this.sqlString = "("+qsqls.join(') AND (')+")"
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.qchooser {
	margin-top: 15px;
}
.redback {
	background-color: red;
	color: white;
}
.greenback {
	background-color: green;
	color: white;
}
</style>
